$light-blue: #a8c3c5;
$blue: #26686d;
$dark-blue: #173e41;

$super-light-green: #eff1f2;
$light-green: #d2f1c1;
$green: #639b47;
$slight-dark-green: #476F33;
$dark-green: #2b421e;

$light-teal: #c5e4dd;
$teal: #6ebbab;
$dark-teal: #2c4b44;

$light-red: #ffd2c4;
$red: #e65f33;
$dark-red: #662a17;

$light-yellow: #ffe9b3;
$yellow: #ffb600;
$dark-yellow: #664900;


$very-light-gray: #eff1f2;
$light-gray: #cfd4d8;
$medium-gray: #868e92;
$dark-gray: #3f4443;
$very-light-grey: #eff1f2;
$light-medium-gray: #dfe3e5;
$light-grey: #cfd4d8;
$medium-grey: #868e92;
$dark-grey: #3f4443;
$very-dark-gray: #212325;

$link-color: #007bff;
$active-link-color: #0056b3;

$primary: $blue;
$secondary: $red;
$tertiary: $teal;
$osu-red: #BA0C2F;
$white: #fff;
$black: #212325;

$osu-scarlet: #BA0C2F;
$osu-light-gray: #EFF1F2;
$osu-gray: #A7B1B7;

$serif: "BuckeyeSerif", "Georgia", serif;
$sans: "BuckeyeSans", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
$sans-extra-bold: "BuckeyeSansExtraBold-Regular", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
