@import "./variables";

html,
body {
  background-color: #fff;
}

.responsive-page-container-sm {
  width: 50%;
  max-width: 1000px;
  padding: 16px;
  margin: 0 auto;
}
.responsive-page-container {
  width: 65%;
  max-width: 1250px;
  padding: 16px;
  margin: 0 auto;
}
@media only screen and (max-width: 768px) {
  .responsive-page-container,
  .responsive-page-container-sm {
    width: 100% !important;
  }
  .responsive-page-container-lg {
    padding: 8px;
  }
}
.responsive-page-container-lg {
  width: 85%;
  max-width: 1400px;
  padding: 16px;
  margin: 0 auto;
}
@media only screen and (max-width: 1400px) {
  .responsive-page-container-lg {
    width: 100% !important;
  }
}

.page-background {
  margin: 0;
  padding: 0;
  width: 100%;
  &.light {
    background-color: $super-light-green;
  }
  &.dark {
    background-color: $black;
    color: $white;
  }
}

// SLIDERS
$default-control-color: #999;

@mixin pagination($pagination-control-color) {
  text-align: center;
  display: block;
  .indicator {
    width: 10px;
    height: 10px;
    background-color: $pagination-control-color;
    border-radius: 50%;
    display: inline-block;
    margin: 4px;
    cursor: pointer;
    vertical-align: middle;
    &.on {
      width: 14px;
      height: 14px;
      background-color: #00000000;
      border: 2px solid $pagination-control-color;
    }
    &:hover {
      opacity: 0.7;
    }
  }
}
.pagination:not(.primary) {
  @include pagination($default-control-color);
}
.pagination.primary {
  @include pagination(transparentize($primary, 0.5));
}

button.prev-btn,
button.next-btn {
  position: absolute;
  top: calc(50% - 22px);
  display: block;
  // & > span.material-icons {
  //   color: $default-control-color;
  //   font-size: 64px;
  // }
  & .slider-arrow-icon-path {
    fill: $default-control-color;
  }
  &:hover,
  &:focus {
    outline: none;
    opacity: 0.7;
  }
  &.next-btn {
    right: 8px;
  }
  &.prev-btn {
    left: 8px;
  }
}
swiper {
  width: 100%;
  &:not(.full-width) {
    width: calc(100% - 128px);
  }
  margin: 0 auto;
}
@media only screen and (max-width: 768px) {
  button.prev-btn,
  button.next-btn {
    display: none;
  }
  swiper {
    width: 100%;
  }
}

.content-list-header {
  display: block;
  color: #333 !important;
  margin: 16px 0;
  text-align: center;
  .subhead {
    font-size: 14pt;
    font-weight: 400;
    i {
      font-size: 12pt;
    }
  }
  &[href]:hover {
    opacity: 0.8;
  }
  &[href]:focus {
    border: 3px dotted #33333390;
  }
}
