@import "variables";

a,
button.link-button {
  color: $link-color;
  display: inline;
  word-wrap: normal;
  padding: 0;
  &:hover,
  &:focus {
    text-decoration: none;
    color: $active-link-color;
    outline: none;
  }
	&.red {
		color: $osu-scarlet;
		text-decoration: underline;

	}
}

:root,
html,
body,
div,
// h1,
// .h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
p,
.navbar,
.brand,
a,
.td-name,
td,
button,
input,
select,
textarea {
  font-family: unset;
  font-family: $sans;
}
::ng-deep .mat-button {
  &,
  * {
    font-family: unset;
    font-family: $sans;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  letter-spacing: 0.02em;
  &.centered {
    text-align: center;
  }
}
h1 {
  font-size: 32pt;
  font-weight: 800;
  font-family: $serif;
}
h2 {
  font-size: 22pt;
  font-weight: 800;
}
h3 {
  font-size: 20pt;
  font-weight: 600;
}
h4 {
  font-size: 1.5rem;
  font-weight: 500;
}
h5 {
  font-size: 1.25rem;
  font-weight: 500;
}
h6 {
  font-size: 1rem;
  font-weight: 500;
}

@mixin campaign-headers($header-primary-color, $header-secondary-color) {
  text-transform: uppercase;
  font-weight: 700;
  color: #00000000;
  text-shadow: 0.05em 0.05em 0 $header-secondary-color;
  -webkit-text-stroke: 0.04em $header-primary-color;
}

hr {
  border-top: 2px solid $primary;
}

