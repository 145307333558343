// CUSTOM ANGULAR MATERIAl THEME
@import "../../../node_modules/@angular/material/_theming.scss";
@import "./variables";
@include mat-core();


// Create a custom typography configuration with BuckeyeSans
$custom-typography: mat-typography-config(
  $font-family: $sans
);

// Override the typography in the core CSS.
@include mat-core($custom-typography);

$kx-primary: (
  50: #f2f2f2,
  100: #e0e0e0,
  200: #bdbdbd,
  300: #9e9e9e,
  400: #757575,
  500: #616161,
  600: #424242,
  700: #303030,
  800: #212121,
  900: #121212,
  A100: #f2f2f2,
  A200: #9e9e9e,
  A400: #303030,
  A700: #000000,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);


$kx-accent: (
  50: #f5fcea,
  100: #e6f6cb,
  200: #d6f1aa,
  300: #c7eb8a,
  400: #bce572,
  500: #b2e15d,
  600: #a8ce55,
  700: #99b84b,
  800: #8ba142,
  900: #737a34,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
  ),
);

$kx-secondary: (
  50: #fcece7,
  100: #f8cfc2,
  200: #f3af99,
  300: #ee8f70,
  400: #ea7752,
  500: #e65f33,
  600: #e3572e,
  700: #df4d27,
  800: #db4320,
  900: #d53214,
  A100: #ffffff,
  A200: #ffd8d2,
  A400: #ffab9f,
  A700: #ff9585,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
  ),
);

$kx-app-primary: mat-palette($kx-primary);
$kx-app-secondary: mat-palette($kx-secondary);
$kx-app-accent: mat-palette($kx-accent);
$kx-app-theme: mat-light-theme($kx-app-primary, $kx-app-accent);
$kx-app-secondary-theme: mat-light-theme($kx-app-secondary, $kx-app-accent);

@include angular-material-theme($kx-app-theme);

.secondary-material-theme {
  @include angular-material-theme($kx-app-secondary-theme);
}

// Override typography for all Angular Material, including mat-base-typography and all components.
@include angular-material-typography($custom-typography);

// ::ng-deep {
.mat-header-row {
  .mat-header-cell {
    padding: 0 16px;
  }
  .mat-sort-header-content {
    text-align: left;
  }
  background-color: $osu-scarlet;
  .mat-header-cell,
  .mat-sort-header-content,
  .mat-sort-header-arrow {
    color: #fff;
    font-weight: bold;
  }
}
.mat-row {
  cursor: pointer;
  &:nth-child(even) {
    background-color: $very-light-gray;
  }
  .mat-cell {
    padding: 16px;
    vertical-align: top;
  }
}
// }
