@import "./variables";

button {
  background: none;
  border: none;
  outline: none;
  &:focus{
    outline:none;
  }
}
button.mat-menu-item {
  outline: none !important;
}
button.osu-button,
a.osu-button {
  margin: 2px;
  border: 2px solid $primary;
  color: $primary;
  padding: 4px 32px;
  transition: all 0.1s;
  font-size: 12pt;
  font-weight: bold;
  display: inline-block;
  &.lg {
    font-size: 16pt;
  }
  &.xl {
    border: 3px solid $primary;
    padding: 8px 64px;
    font-size: 16pt;
    font-weight: bold;
  }
  &:hover,
  &:focus {
    background-color: $primary;
    color: #fff;
  }
}
