.image-slider .kx-original-tag,
.content-list-grid .kx-original-tag {
  background-color: $primary;
  color: #fff;
  font-weight: 700;
  font-size: 10pt;
  line-height: 10pt;
  display: inline-block;
  padding: 4px 32px 2px 32px;
  transform: rotate(-45deg);
  position: relative;
  left: -26px;
  top: 2px;
}
