@import "variables";

.wide-page-header {
  width: 100%;
  font-weight: 700;
  padding: 32px 0;
  &.short {
    padding: 16px 0;
  }
  margin-bottom: 16px;
  text-align: center;
  &.light {
    color: $black;
  }
  &.dark {
    background-color: $black;
    color: $white;
  }
  &.yellow {
    background-color: $yellow;
    &.light {
      background-color: $light-yellow;
    }
    &.dark {
      background-color: $dark-yellow;
    }
  }
  &.red {
    background-color: $red;
    &.light {
      background-color: $light-red;
    }
    &.dark {
      background-color: $dark-red;
    }
  }
  &.blue {
    background-color: $blue;
    &:not(.light) {
      color: $white;
    }
    &.light {
      background-color: $light-blue;
    }
    &.dark {
      background-color: $dark-blue;
    }
  }
  &.teal {
    background-color: $teal;
    &.light {
      background-color: $light-teal;
    }
    &.dark {
      background-color: $dark-teal;
    }
  }
  &.green {
    background-color: $green;
    &.light {
      background-color: $light-green;
    }
    &.dark {
      background-color: $dark-green;
    }
  }
}

.journalistic-header {
  &.responsive-page-container-lg {
    max-width: 1200px !important;
  }
  padding-bottom: 0 !important;
  h1 {
    margin: 16px 0;
  }
  .subtitle {
    font-size: 18pt;
    font-style: italic;
    font-weight: 300;
    color: $medium-gray !important;
  }
  .attribution {
    display: flex;
    .text {
      > * {
        font-size: 12pt;
        line-height: 12pt;
        color: $medium-gray !important;
      }
      text-transform: uppercase;
      font-weight: 600;
      letter-spacing: 0.02rem;
      .byline {
        color: $black !important;
        margin-bottom: 2px;
      }
    }
    app-social-links {
      flex-grow: 1;
    }
  }
  .header-img {
    width: 100%;
    max-height: calc(512px + 4vw);
    object-fit: cover;
    position: relative;
    margin: 16px 0;
    overflow: hidden;
  }
}
